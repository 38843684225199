.section-footer {
    color: #fff;
    background: $secondary-color;

    &__inside {
        padding: 28px 36px 20px;

        @include medium {
            padding-right: $indent-tablet;
            padding-left: $indent-tablet;
        }

        @include large {
            max-width: 1920px;
            margin-right: auto;
            margin-left: auto;
            padding-right: $indent-desktop;
            padding-left: $indent-desktop;
        }
    }

    &__logo {
        margin-bottom: 30px;

        > a {
            display: block;
            width: 166px;
            height: 45px;
            overflow: hidden;
            text-indent: -999em;
            background: url('../../images/logo__de--white.svg') 0 0 no-repeat;

            html[lang='fr'] & {
                background: url('../../images/logo__fr--white.svg') 0 0 no-repeat;
            }

            html[lang='it'] & {
                background: url('../../images/logo__it--white.svg') 0 0 no-repeat;
            }
        }
    }

    &__navigation,
    &__meta {
        a {
            font-family: $font-ClanPro;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__navigation {
        position: relative;
        padding: 5px 0;
        font-size: 16px;
        border-top: 2px solid #dce7ea;

        &::after {
            position: absolute;
            top: 16px;
            right: 15px;
            width: 15px;
            height: 8px;
            background: url('../../images/footer-collapse.svg') 0 0 no-repeat;
            transform: rotateZ(0deg);
            transition: transform 0.2s ease-in-out;
            content: '';
        }

        > .title {
            font-size: 18px;
            font-family: $font-UnitSlabBold;
            cursor: pointer;
        }

        a {
            display: block;
        }

        ul {
            overflow: hidden;
            transition: height 0.2s ease-in-out;
        }

        &.collapse {
            &::after {
                transform: rotateZ(180deg);
            }
        }

        li {
            padding: 5px 0;

            &.top-margin {
                margin-top: 1.75em;
            }

            &:first-child {
                margin-top: 0.75em;
            }

            &:last-child {
                margin-bottom: 0.75em;
            }
        }
    }

    &__meta {
        padding-top: 30px;
        font: normal 14px/28px $font-ClanPro;
        border-top: 2px solid #dce7ea;

        ul,
        li {
            display: inline;
        }

        li {
            &::before {
                margin: 0 5px;
                content: ' | ';
            }

            &:first-child::before {
                content: none;
            }
        }
    }

    .content-text {
        p {
            margin: 1.5em 0;
            color: #fff;
            font: normal 12px/18px $font-ClanPro;
        }
    }

    @include medium {
        &__inside {
            display: flex;
            flex-wrap: wrap;
            padding-top: 80px;
        }

        &__logo {
            width: 34%;
            padding-top: 8px;

            > a {
                width: 70%;
                height: 100%;
            }
        }

        &__navigation {
            width: 22%;
            padding: 0;
            border: none;

            &::after {
                content: none;
            }

            > .title {
                cursor: initial;
            }

            a {
                transition: padding-left 0.2s ease-in-out;

                &:hover {
                    padding-left: 12px;
                    font-family: $font-ClanProBold;
                    text-decoration: none;
                }
            }
        }

        &__meta,
        .content-text {
            width: 100%;
            margin-left: 34%;
            border: none;
        }

        &__meta {
            padding-top: 50px;
        }
    }

    @include large {
        &__inside {
            padding-top: 116px;
        }

        &__meta {
            padding-top: 100px;
        }
    }
}
