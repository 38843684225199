body.overlay-visible {
    overflow: hidden;
}

.section-header {
    &::after {
        display: table;
        clear: both;
        content: '';
    }

    body.has-overlay & {
        height: 62px;
    }

    &__inside {
        position: relative;
        padding: 15px $indent-mobile;
    }

    &__logo {
        position: absolute;
        top: 15px;
        left: $indent-mobile;
        z-index: 20;

        > a {
            display: block;
            width: 123px;
            height: 33px;
            overflow: hidden;
            text-indent: -999em;
            background: url('../../images/logo__de.svg') center center no-repeat;

            html[lang='fr'] & {
                background: url('../../images/logo__fr.svg') center center no-repeat;
            }

            html[lang='it'] & {
                background: url('../../images/logo__it.svg') center center no-repeat;
            }
        }
    }

    &__toggle {
        display: none;

        body.has-overlay & {
            position: absolute;
            top: 24px;
            right: 36px;
            z-index: 20;
            display: block;
            width: 23px;
            height: 15px;
            background: none;
            border: none;
            cursor: pointer;
            transition: 0.3s;

            > span {
                position: relative;
                display: block;
                width: 23px;
                height: 2px;
                background: $text-color;
                transition: 0.3s;

                &::before,
                &::after {
                    position: absolute;
                    left: 0;
                    display: inline-block;
                    width: 23px;
                    height: 2px;
                    background: $text-color;
                    transition: 0.3s;
                    content: '';
                }

                &::before {
                    top: 7px;
                }

                &::after {
                    top: -7px;
                }
            }

            &:hover > span {
                &::before {
                    top: 8px;
                }

                &::after {
                    top: -8px;
                }
            }
        }

        body.has-overlay.overlay-visible & {
            > span {
                background: transparent;

                &::before,
                &::after {
                    top: 0;
                    transform-origin: 50% 50%;
                }

                &::before {
                    transform: rotate3d(0, 0, 1, 45deg);
                }

                &::after {
                    transform: rotate3d(0, 0, 1, -45deg);
                }
            }
        }
    }

    &__overlay {
        padding: 0 0 30px;
        text-align: right;

        body.has-overlay & {
            position: fixed;
            top: 62px;
            right: 0;
            left: 0;
            z-index: 10;
            display: none;
            height: calc(100vh - 80px);
            height: calc((var(--vh, 1vh) * 100) - 80px);
            padding-top: 18px;
            padding-right: $indent-mobile;
            padding-left: $indent-mobile;
            overflow-y: scroll;
            background: #fff;
        }

        body.has-overlay.overlay-visible & {
            display: block;
        }
    }

    &__navigation {
        margin-bottom: 25px;
        padding-bottom: 25px;
        text-align: left;
        border-bottom: 2px solid $border-color;

        strong.submenu {
            cursor: pointer;
        }

        strong,
        a.link {
            display: block;
            font-size: 18px;
            font-family: $font-ClanProBold;
        }

        li:not(:first-child) {
            strong.submenu,
            a.link {
                margin-top: 35px;
            }
        }

        div.submenu {
            overflow: hidden;
            transition: height 0.2s ease-in-out;
        }

        .summary {
            display: none;
        }

        .description {
            display: block;
            margin: 5px 0 15px;
        }

        .more {
            display: inline-block;
            margin: 15px 0;
            padding-right: 65px;
            color: #00bed5;
            font-size: 16px;
            font-family: $font-ClanProBold;
            line-height: 28px;
            background: url('../../images/arrow.svg') 100% 0 no-repeat;
            background-size: 40px 30px;
            transition: padding-right 0.2s ease-in-out;

            &:hover {
                padding-right: 85px;
            }
        }

        div.warning {
            display: none;
        }

        .level_2 {
            margin-top: 20px;
            border-bottom: 2px solid #dce7ea;

            li {
                padding: 11px 0;
                font-size: 16px;
                font-family: $font-ClanPro;
                line-height: 1.5;
                border-top: 2px solid #dce7ea;
            }

            a {
                display: block;
            }
        }
    }

    &__buttons {
        margin-bottom: 36px;

        ul {
            display: flex;
            justify-content: center;
        }

        li {
            display: inline-block;
        }

        a {
            display: block;
            min-width: 122px;
            height: 42px;
            margin: 0 14px;
            padding: 11px 20px 9px;
            font: normal 14px/18px $font-ClanPro;
            text-align: center;
            border: 2px solid #cad5d8;
            border-radius: 6px;
            transition:
                color 0.2s ease-in-out,
                background-color 0.2s ease-in-out,
                border-color 0.2s ease-in-out;

            &.login {
                span {
                    display: inline-block;
                    padding-left: 20px;
                    background: url('../../images/login.svg') -5px 0 no-repeat;
                    background-size: 20px 14px;
                }

                &:hover {
                    color: $text-color;
                }
            }

            &.emergency {
                color: #fff;
                background: #d8605b;
                border-color: #d8605b;
            }

            &:hover {
                color: #fff;
                background: #008faa;
                border-color: #008faa;
            }
        }
    }

    &__language,
    &__links {
        display: inline-block;
        font-size: 14px;
        font-family: $font-ClanProBold;
        line-height: 18px;

        ul,
        li {
            display: inline;
        }

        a {
            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__language {
        margin-right: 15px;

        li {
            &::before {
                margin: 3px;
                content: ' | ';
            }

            &:first-child::before {
                content: none;
            }
        }
    }

    @include medium {
        &__inside {
            padding: 0 $indent-tablet;
        }

        &__logo {
            top: 37px;
            left: $indent-tablet;

            > a {
                width: 220px;
                height: 60px;
            }
        }

        &__overlay {
            position: relative;
            z-index: 10;
            height: 140px;
            padding: 15px 0 0;
        }

        &__navigation {
            position: absolute;
            top: 76px;
            z-index: -1;
            width: 100%;
            padding-left: 270px;
            overflow: visible;
            border: none;

            .level_1 {
                display: flex;
                align-items: flex-end;
                justify-content: flex-start;

                > li {
                    padding: 0 10px;

                    > strong,
                    > a.link {
                        position: relative;
                        z-index: 2;
                        margin-top: 0 !important;
                        white-space: pre;
                        text-overflow: ellipsis;
                        border-bottom: 6px solid transparent;
                    }

                    &:hover > strong,
                    &:hover > a.link,
                    &.active > a.link,
                    &.trail > strong,
                    &.trail > a.link {
                        &.color--blue {
                            border-bottom-color: #008faa;
                        }

                        &.color--orange {
                            border-bottom-color: #ffc98b;
                        }

                        &.color--red {
                            border-bottom-color: #f4a3a0;
                        }

                        &.color--yellow {
                            border-bottom-color: #f4dc78;
                        }
                    }
                }
            }

            div.submenu {
                position: absolute;
                left: -$indent-tablet;
                z-index: 1;
                display: none;
                width: 100vw;
                margin-top: 29px;
                padding: 30px $indent-tablet 75px;
                overflow: visible;
                background: #fff;
                border-top: 2px solid #dce7ea;
                box-shadow: 0 10px 60px #cad5d8;
                transition: none;

                &::before {
                    position: absolute;
                    top: -100px;
                    right: 0;
                    left: 0;
                    z-index: -1;
                    height: 98px;
                    background: #fff;
                    content: '';
                }

                .summary {
                    display: block;
                    width: 220px;
                    margin-top: 30px;
                    margin-right: 60px;
                }

                ul {
                    min-width: 260px;
                    max-width: 350px;

                    a {
                        transition: padding-left 0.2s ease-in-out;

                        &.active {
                            font-family: $font-ClanProBold;
                            text-decoration: none;
                        }

                        &:hover {
                            padding-left: 12px;
                            font-family: $font-ClanProBold;
                            text-decoration: none;
                        }
                    }
                }

                div.warning {
                    display: flex;
                    flex-grow: 1;
                    justify-content: flex-end;
                    margin: -30px -60px -75px 0;

                    > .box {
                        width: 40vw;
                        padding: 50px 60px 50px 15px;
                        background: $background-color;
                    }
                }
            }

            li.submenu:hover {
                div.submenu {
                    display: flex;
                }
            }
        }

        &__buttons {
            float: right;
            margin: 0 0 0 15px;
            padding: 0;

            li:last-child a {
                margin-right: 0;
            }
        }

        &__link,
        &__language {
            margin-top: 12px;
        }
    }

    @include large {
        &__inside {
            max-width: 1920px;
            margin: 0 auto;
            padding: 0 $indent-desktop;
        }

        &__logo {
            top: 67px;
            left: $indent-desktop;
        }

        &__overlay {
            height: 169px;
            padding-top: 25px;
        }

        &__navigation {
            top: 106px;
            padding-left: 260px;

            .level_1 > li {
                padding: 0 20px;
            }

            div.submenu {
                left: -$indent-desktop;
                padding-right: $indent-desktop;
                padding-left: $indent-desktop;

                div.warning {
                    margin-right: -140px;

                    > .box {
                        padding-right: 140px;
                        padding-left: 50px;
                    }
                }
            }
        }
    }

    @include xl {
        &__navigation {
            padding-left: 370px;

            .level_1 > li {
                padding: 0 30px;
            }

            div.submenu {
                .summary {
                    width: 330px;
                    margin-right: 70px;
                }
            }
        }
    }

    @include xxl {
        &__overlay {
            div.submenu {
                left: calc((1920px - 100vw) / 2 - 140px);
                padding-left: calc((100vw - 1920px) / 2 + 140px);
            }
        }

        &__navigation div.submenu div.warning > .box {
            padding-right: calc((100vw - 1920px) / 2 + 140px);
        }
    }
}
