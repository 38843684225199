@import 'fonts';

html {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

body {
    margin: 0;
    padding: 0;
    text-size-adjust: none;
}

input,
button,
textarea,
select,
fieldset {
    margin: 0;
    padding: 0;
    color: inherit;
    font-weight: inherit;
    font-size: 99%;
    font-family: inherit;
    border-radius: 0;
}

textarea,
button,
input[type='email'],
input[type='tel'],
input[type='text'],
input[type='submit'],
input[type='search'] {
    appearance: none;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button {
    display: none;
}

textarea[disabled],
button[disabled],
input[type='email'][disabled],
input[type='tel'][disabled],
input[type='text'][disabled],
input[type='submit'][disabled],
input[type='search'][disabled] {
    cursor: not-allowed;
}

select[disabled],
button[disabled],
input[disabled],
input[type='checkbox'][disabled] + label,
input[type='radio'][disabled] + label {
    cursor: not-allowed;
}

table {
    //width: 100%;
    font-size: inherit;
    border-collapse: collapse;
    border-spacing: 0;
}

table td,
table th {
    padding: 4px 15px;
    vertical-align: top;
}

tr > :first-child {
    padding-left: 0;
}

th {
    font-weight: normal;
    font-family: $font-UnitSlabBold;
}

caption,
strong,
b {
    font-weight: normal;
    font-family: $font-UnitSlabBold;
}

ul ul {
    margin-bottom: 0;
}

form,
figure,
blockquote {
    margin: 0;
    padding: 0;
}

img,
iframe {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
    border: none;
    border: 0;
}

iframe {
    width: 100%;
}

header,
footer,
nav,
section,
main,
aside,
article,
figure,
figcaption {
    display: block;
}

html {
    font-size: 16px;
}

body {
    color: $text-color;
    font-weight: normal;
    font-size: 16px;
    font-family: $font-UnitSlab;
    line-height: 1.625;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: normal;
    font-family: $font-UnitSlabBold;
}

a {
    color: inherit;
    text-decoration: none;
}

caption,
th,
td {
    //noinspection CssOverwrittenProperties
    text-align: left;

    //noinspection CssOverwrittenProperties
    text-align: start;
}

abbr,
acronym {
    font-variant: normal;
    border-bottom: 1px dotted $text-color;
    cursor: help;
}

blockquote,
q {
    quotes: none;
}

sup {
    vertical-align: text-top;
}

sub {
    vertical-align: text-bottom;
}

del {
    text-decoration: line-through;
}

ins {
    text-decoration: none;
}

pre,
code {
    font-family: monospace;
}

/* Vertical align */
.float_left {
    float: left;
}

.float_right {
    float: right;
}

/* Clear floats */
.clear,
#clear {
    clear: both;
    height: 0.1px;
    font-size: 0.1px;
    line-height: 0.1px;
}

/* Hide invisible elements */
.invisible {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    margin: -1px !important;
    padding: 0 !important;
    overflow: hidden !important;
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
}

.mod_navigation ul,
.mod_navigation li,
.mod_changelanguage ul,
.mod_changelanguage li {
    margin: 0;
    padding: 0;
    list-style-type: none;
}
