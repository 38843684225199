.link-button {
    display: inline-block;
    padding: 13px 20px 11px;
    color: #fff;
    font: normal 13px/17px $font-ClanPro;
    background: $primary-color;
    border: 2px solid $primary-color;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.1s ease-in-out;

    &:hover {
        color: #fff;
        text-decoration: none;
        background: $secondary-color;
        border: 2px solid $secondary-color;
    }

    @include medium {
        padding: 20px 40px 19px;
        font-size: 16px;
        line-height: 21px;
    }
}

.mod_article,
.section-header__navigation div.warning {
    font-size: 15px;

    @include medium {
        font-size: 22px;
        line-height: 28px;
    }

    &.background {
        background: $background-color;
    }

    > .inside {
        padding: $vmargin-mobile $indent-mobile;
        overflow: hidden;

        @include medium {
            padding: $vmargin-desktop $indent-tablet;
        }

        @include large {
            max-width: 1920px;
            margin-right: auto;
            margin-left: auto;
            padding: 78px $indent-desktop;
        }
    }

    h1 {
        margin: 0 0 0.5em;
        font-size: 30px;
        line-height: 40px;

        @include medium {
            font-size: 56px;
            line-height: 68px;
        }
    }

    h2 {
        margin: 0 0 0.5em;
        font-size: 26px;
        line-height: 34px;

        @include medium {
            font-size: 42px;
            line-height: 52px;
        }
    }

    h3 {
        margin: 0 0 0.5em;
        font-size: 26px;
        line-height: 34px;

        @include medium {
            font-size: 32px;
            line-height: 41px;
        }
    }

    .text-title {
        font: normal 22px/28px $font-UnitSlabBold;

        @include medium {
            font-size: 28px;
            line-height: 35px;
        }
    }

    p:not(.text-title) {
        hyphens: auto;
    }

    a {
        color: $primary-color;

        &:hover {
            text-decoration: underline;
        }
    }
}

.content-text,
.ce_list,
.ce_rsce_warning,
.ce_rsce_teaser {
    ul {
        margin: 0;
        padding: 0;
    }

    li {
        position: relative;
        margin: 0;
        padding: 0 0 0 10px;
        list-style-type: none;

        &::before {
            position: absolute;
            top: 3px;
            left: 0;
            font-size: 18px;
            line-height: 20px;
            content: '•';
        }
    }
}

.content-text,
.ce_list,
.content-hyperlink,
.ce_rsce_teaser {
    + .content-text {
        > h1,
        h2,
        h3 {
            margin-top: $vmargin-mobile;

            @include medium {
                margin-top: $vmargin-desktop;
            }
        }
    }
}

h3.ce_headline,
.content-text h3,
.content-text p,
.content-text li,
.ce_list li,
.mod_counseling_list p,
.mod_counseling_reader p {
    max-width: 1000px;

    .mod_article.wide &,
    .mod_article.topic & {
        max-width: 1250px;
    }
}

.content-hyperlink,
.ce_rsce_counseling {
    &.text-left {
        text-align: left;
    }

    &.text-center {
        text-align: center;
    }

    &.text-right {
        text-align: right;
    }

    &.button {
        margin-top: $vmargin-mobile;

        > a,
        > button {
            @extend .link-button;
        }
    }

    @include medium {
        &:not(.button) {
            font-size: 24px;
            line-height: 30px;
        }
    }
}

.mod_article.intro {
    .content-image {
        text-align: right;

        img {
            width: auto;
            height: 63px;
        }

        @include medium {
            float: right;
            width: 300px;
            margin-bottom: 50px;
            margin-left: 50px;

            img {
                width: 100%;
                height: auto;
            }
        }
    }

    .content-text {
        p {
            max-width: none;

            &:last-child {
                margin-bottom: 0;
            }
        }

        @include xl {
            overflow: hidden;
        }
    }
}

.mod_article.topic {
    color: #fff;

    h1 {
        margin: 0;
    }

    @include small {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 25vh;
    }

    @include medium {
        padding: 50px 0;
    }

    .inside {
        width: 100%;
    }

    + .mod_article > .inside {
        padding-top: $vmargin-mobile;
        padding-bottom: $vmargin-mobile;

        .content-text {
            overflow: hidden;

            > p:first-child {
                margin-top: 1em;
            }
        }

        .ce_rsce_warning {
            margin-bottom: 0;
        }
    }
}

@import 'elements/safezone';
@import 'elements/counseling';
