$screens: (
    mobile-to: 959px,
    tablet-from: 960px,
    tablet-to: 1199px,
    desktop-from: 1200px,
);
$font-UnitSlab: unitslabwebpro, sans-serif;
$font-UnitSlabBold: unitslabwebpro-bold, sans-serif;
$font-ClanPro: clanwebpro-news, sans-serif;
$font-ClanProBold: clanwebpro-bold, sans-serif;
$indent-mobile: 36px;
$indent-tablet: 60px;
$indent-desktop: 140px;
$vmargin-mobile: 38px;
$vmargin-desktop: 76px;
$primary-color: #00bed5;
$secondary-color: #008faa;
$text-color: #222;
$border-color: #bcbcbc;
$error-color: #f2809d;
$background-color: #f5f9fa;
$warning-color: #f2ebbb;

@mixin mobile-only() {
    @media (width <= 959px) {
        @content;
    }
}

@mixin mini() {
    @media (width >= 480px) {
        @content;
    }
}

@mixin small() {
    @media (width >= 768px) {
        @content;
    }
}

@mixin medium() {
    @media (width >= 960px) {
        @content;
    }
}

@mixin large() {
    @media (width >= 1200px) {
        @content;
    }
}

@mixin xl() {
    @media (width >= 1600px) {
        @content;
    }
}

@mixin xxl() {
    @media (width >= 1920px) {
        @content;
    }
}

@import 'layout';
@import 'visibility';
@import 'forms';
@import 'elements';
@import 'widget';
@import 'sections/header';
@import 'sections/footer';

.required-images {
    background: url('../images/warning.svg');
}
