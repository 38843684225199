.widget {
    &-cta {
        @apply flex flex-col items-end w-full;

        @media (width <= 768px) {
            margin-bottom: 60px;
        }

        a {
            color: var(--primary-color) !important;

            &:hover {
                color: var(--text-color) !important;
            }
        }
    }
}

.onboarding-widget > div {
    inset: 0 auto auto 0;
    z-index: 1000;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: none !important;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);

    & > div {
        &::before {
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            width: 100%;
            height: 100%;
            background-color: rgb(0 0 0 / 60%);
            content: '';
        }
    }

    & > button {
        align-self: auto !important;

        @media (width >= 416px) {
            margin-left: 337px;
        }
    }
}
