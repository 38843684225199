.sz-nav-topics {
    overflow: visible;

    ul,
    li {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    ul {
        li {
            a {
                position: relative;
                display: block;
                margin: 0 0 9px;
                transition: transform 0.2s ease-in-out;

                &:hover {
                    transform: scale(1.03);
                }
            }

            span {
                position: absolute;
                bottom: 15px;
                left: 15px;
                color: #fff;
                font-family: $font-ClanProBold;
            }
        }

        &.tns-slider li {
            margin: 0;
        }
    }

    .tns-ovh {
        overflow: visible !important;
    }

    .tns-outer {
        margin: 0 -60px;
    }

    .tns-controls {
        display: flex;
        justify-content: center;
        margin: 20px 60px 0;
        text-align: center;
        outline: none;

        button {
            position: relative;
            display: block;
            width: 37px;
            height: 37px;
            margin: 15px;
            text-indent: -999em;
            background: none;
            border: 2px solid #00bed5;
            border-radius: 50%;
            outline: none;
            transition: border-color 0.1s ease-in-out;

            &::after {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 10px;
                height: 10px;
                margin-top: -5px;
                margin-left: -6px;
                border-top: 2px solid #00bed5;
                border-right: 2px solid #00bed5;
                border-top-right-radius: 2px;
                transform: rotateZ(45deg);
                transition: border-color 0.1s ease-in-out;
                content: '';
            }

            &:first-child::after {
                margin-left: -3px;
                transform: rotateZ(-135deg);
            }

            &:hover {
                border-color: #008faa;

                &::after {
                    border-color: #008faa;
                }
            }

            &:disabled {
                border-color: #dce7ea;

                &::after {
                    border-color: #dce7ea;
                }
            }
        }
    }

    @include medium {
        ul {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -16px;

            li {
                width: calc(33.3333% - 32px);
                margin: 0 16px 32px;
            }

            &.tns-slider {
                margin: 0;

                li {
                    width: auto;
                    margin: 0;
                }
            }
        }

        .tns-outer {
            margin: 0 -120px;
        }

        .tns-controls {
            margin: 40px 120px 0;

            button {
                width: 58px;
                height: 58px;
                margin-right: 18px;
                margin-left: 18px;

                &::after {
                    width: 15px;
                    height: 15px;
                    margin-top: -8px;
                    margin-left: -10px;
                }

                &:first-child::after {
                    margin-left: -5px;
                }
            }
        }
    }
}

.ce_rsce_link-box {
    margin-top: $vmargin-mobile;

    .item {
        display: block;
        color: #222;
        border-top: 2px solid #bcbcbc;

        &:hover {
            text-decoration: none;
        }
    }

    .link {
        display: inline-block;
        margin-bottom: 2em;
        color: #00bed5;
        font-family: $font-UnitSlabBold;
    }

    .item:hover {
        .link {
            text-decoration: underline;
        }
    }

    @include medium {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        margin-right: -16px;
        margin-left: -16px;

        .item {
            width: calc(33.3333% - 32px);
            margin-right: 16px;
            margin-left: 16px;
        }
    }

    &.arrow {
        .link {
            width: 35px;
            height: 22px;
            text-indent: -999em;
            background: url('../../images/arrow.svg') 0 0 no-repeat;
            transition: margin-left 0.2s ease-in-out;
        }

        .item:hover .link {
            margin-left: 20px;
        }
    }

    &.center {
        text-align: center;

        @include large {
            p {
                max-width: 80%;
                margin-right: auto;
                margin-left: auto;
            }
        }
    }

    &.phone {
        .item {
            padding: 1em 50px 0;
            text-align: center;
        }

        h3 {
            position: relative;
            display: inline;

            &::before {
                position: absolute;
                top: 0;
                left: -40px;
                display: block;
                width: 25px;
                height: 25px;
                background: url('../../images/phone.svg') 0 0 no-repeat;
                background-size: 100% 100%;
                content: '';

                @include medium {
                    top: 4px;
                    left: -50px;
                }
            }
        }

        p {
            margin: 9px 0 27px;
        }
    }
}

.ce_rsce_teaser {
    margin-top: $vmargin-mobile;

    &:first-child {
        margin-top: 0;
    }

    .image_container {
        height: 130px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
    }

    p {
        margin-bottom: 0 !important;
    }

    @include small {
        display: flex;
        max-width: 1150px;
        margin-top: $vmargin-desktop;
        margin-right: auto;
        margin-left: auto;

        .image_container {
            width: 30%;
            height: auto;
            margin-right: 5%;
            background-position: right center;
        }

        .content-text {
            width: 65%;

            p.text-title {
                margin-top: 0;
            }
        }
    }
}

.ce_rsce_warning {
    margin-bottom: $vmargin-mobile;
    padding: 10px 30px;
    background: $warning-color;

    .image_container {
        float: right;
        width: 80px;
        height: 80px;
        margin: 10px -10px 10px 10px;
    }

    .text-title {
        margin: 0 -15px 0 0;
        padding: 1em 0;
        hyphens: auto;

        + p {
            margin-top: 0;
        }
    }

    @include medium {
        float: right;
        max-width: 40%;
        margin-left: 38px;
    }

    @include large {
        max-width: 450px;
        padding: 10px 60px;

        .image_container {
            margin: 20px -20px 20px 20px;
        }
    }

    @media (width >= 1880px) {
        max-width: 550px;
    }

    + .content-text {
        > p:first-child {
            margin-top: 0;
        }
    }
}

.ce_rsce_chatbot {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-right: -10px;
    margin-left: -10px;
    text-align: center;

    a {
        margin: 10px;
        color: #222;
        text-decoration: none;

        img {
            height: 140px;
        }

        .button {
            @extend .link-button;
            margin-top: 10px;
        }

        &:hover {
            text-decoration: none;

            & .button {
                color: #fff;
                background: #008faa;
                border: 2px solid #008faa;
            }
        }
    }
}

.ce_rsce_downloads {
    float: right;

    a {
        display: block;
        width: 100px;
        margin: 0 0 1em 1em;
    }

    img {
        width: 100%;
        height: auto;
    }

    @include medium {
        a {
            width: 150px;
        }
    }

    @media (width >= 1720px) {
        a {
            display: inline-block;
            width: 200px;
        }
    }
}

.mdc-iframe {
    display: block;
    width: 100%;
    height: auto;
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
    max-height: 100%;
    border: none;

    @media (width <= 767px) {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9999;
        height: 100vh !important;
        height: calc(var(--vh, 1vh) * 100) !important;
        background-color: #fff;
    }
}
