@font-face {
    font-family: ClanWebPro-Bold;
    src: url('../fonts/ClanWebPro-Bold.woff') format('woff');
}

@font-face {
    font-family: ClanWebPro-News;
    src: url('../fonts/ClanWebPro-News.woff') format('woff');
}

@font-face {
    font-family: UnitSlabWebPro;
    src: url('../fonts/UnitSlabWebPro.woff') format('woff');
}

@font-face {
    font-family: UnitSlabWebPro-Bold;
    src: url('../fonts/UnitSlabWebPro-Bold.woff') format('woff');
}
