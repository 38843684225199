@use 'sass:map';

.mobile-only {
    @media (min-width: map.get($screens, tablet-from)) {
        display: none;
    }
}

.mobile-hidden,
.tablet-and-desktop {
    @media (max-width: map.get($screens, mobile-to)) {
        display: none;
    }
}

.tablet-only {
    @media (max-width: map.get($screens, mobile-to)), (min-width: map.get($screens, desktop-from)) {
        display: none;
    }
}

.tablet-hidden,
.mobile-and-desktop {
    @media (min-width: map.get($screens, tablet-from)) and (max-width: map.get($screens, tablet-to)) {
        display: none;
    }
}

.desktop-only {
    @media (max-width: map.get($screens, tablet-to)) {
        display: none;
    }
}

.mobile-and-tablet,
.desktop-hidden {
    @media (min-width: map.get($screens, desktop-from)) {
        display: none;
    }
}
