.mod_article.questions {
    > .inside {
        max-width: none !important;
        padding: 0 !important;
    }
}

.ce_counseling_list {
    overflow: visible;

    .item {
        position: relative;
        margin-bottom: 60px;
        padding: 10px 28px 30px;
        background: #00bed5;
        border-radius: 8px;
        box-shadow: 0 10px 40px #dce7ea;

        &::after {
            position: absolute;
            bottom: -75px;
            left: 50%;
            display: block;
            width: 0;
            height: 0;
            border-top: 40px solid #00bed5;
            border-right: 20px solid transparent;
            border-bottom: 40px solid transparent;
            border-left: 20px solid transparent;
            border-radius: 6px;
            transform: translateX(-50%);
            content: '';
        }

        .content-hyperlink {
            margin-top: 25px;
            text-align: center;
        }

        p {
            color: #fff;
            font-size: 16px;
            line-height: 24px;
        }

        a {
            color: #fff;
            background: #00bed5;
            border: 2px solid #fff;
            border-radius: 6px;
            transition: all 0.1s ease-in-out;

            &:hover {
                background: #008faa;
                border-color: #008faa;
            }
        }
    }

    @include medium {
        .items {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -20px;
        }

        .item {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: calc(33.3333% - 40px);
            height: 320px;
            margin-right: 20px;
            margin-left: 20px;

            p {
                display: -webkit-box;
                overflow: hidden;
                font-size: 20px;
                line-height: 25px;
                -webkit-line-clamp: 6;
                -webkit-box-orient: vertical;
            }
        }
    }
}

.mod_counseling_list {
    .items {
        border-top: 2px solid #dce7ea;

        + .items {
            border-top: none !important;
        }
    }

    .empty {
        margin-bottom: $indent-mobile;
        padding: 0 $indent-mobile;
    }

    .item {
        display: block;
        color: #222;
        border-bottom: 2px solid #dce7ea;

        > .inside {
            padding: 0 $indent-mobile;
        }
    }

    .more {
        margin-top: $vmargin-mobile;
        margin-bottom: $vmargin-mobile;
        text-align: center;

        > a {
            @extend .link-button;
            position: relative;
            color: $text-color;
            background: none;
            border: 2px solid $border-color;

            &.loading {
                color: $secondary-color;
                background: $secondary-color;
                border-color: $secondary-color;
            }
        }

        div.loading {
            position: absolute;
            top: calc(50% - 1em);
            left: calc(50% - 1em);
            width: 2em;
            height: 2em;
            background: #fff;
            background: linear-gradient(to right, #fff 10%, rgb(255 255 255 / 0%) 42%);
            border-radius: 50%;
            transform: translateZ(0);
            animation: loading 1.4s infinite linear;

            &::before {
                position: absolute;
                top: 0;
                left: 0;
                width: 50%;
                height: 50%;
                background: #fff;
                border-radius: 100% 0 0;
                content: '';
            }

            &::after {
                position: absolute;
                inset: 0;
                width: 75%;
                height: 75%;
                margin: auto;
                background: $secondary-color;
                border-radius: 50%;
                content: '';
            }

            @keyframes loading {
                0% {
                    transform: rotate(0deg);
                }

                100% {
                    transform: rotate(360deg);
                }
            }
        }
    }

    @include medium {
        .empty {
            margin-bottom: $indent-tablet;
            padding: 0 $indent-tablet;
        }

        .item > .inside {
            padding: 0 $indent-tablet;
        }

        .more {
            margin-bottom: $vmargin-desktop;
        }
    }

    @include large {
        .empty {
            max-width: 1920px;
            margin: 0 auto $indent-desktop;
            padding: 0 $indent-desktop;
        }

        .item > .inside {
            max-width: 1920px;
            margin: 0 auto;
            padding: 0 $indent-desktop;
        }
    }
}

.mod_counseling_filter {
    margin-bottom: 20px;
    padding-top: 10px;

    .formbody {
        display: flex;
        flex-wrap: wrap;
        padding: 0 $indent-mobile;
    }

    .widget-text {
        width: 70%;

        input {
            padding-right: calc(2% + 40px);
            background: url('../../images/search.svg') 98% 50% no-repeat;
            background-size: 25px 25px;
        }
    }

    .widget-submit {
        width: 30%;
        padding-left: 10px;

        button {
            width: 100%;
            line-height: 16px;
        }
    }

    .widget-select {
        width: 100%;
    }

    @include medium {
        margin-bottom: 60px;

        .formbody {
            flex-wrap: nowrap;
            padding: 0 $indent-tablet;
        }

        .widget-text {
            flex-grow: 4;
            width: auto;

            input {
                min-width: 350px;
            }
        }

        .widget-submit {
            flex-grow: 1;
            width: auto;

            button {
                padding: 16px 20px 14px !important;
                line-height: 18px !important;
            }
        }

        .widget-select {
            flex-grow: 2;
            width: auto;
            margin-left: 50px;
        }
    }

    @include large {
        .formbody {
            max-width: 1920px;
            margin: 0 auto;
            padding: 0 $indent-desktop;
        }
    }
}

.mod_counseling_reader {
    header {
        background: $background-color;
    }

    .inside {
        padding: $vmargin-mobile $indent-mobile;
    }

    h1 {
        font-size: 26px;
        line-height: 34px;
    }

    @include medium {
        .inside {
            padding: $vmargin-desktop $indent-tablet $vmargin-mobile;
        }

        h1 {
            max-width: 1250px;
            font-size: 42px;
            line-height: 52px;
        }
    }

    @include large {
        .inside {
            max-width: 1920px;
            margin: 0 auto;
            padding: $vmargin-desktop $indent-desktop $vmargin-mobile;
        }
    }
}

.mod_counseling_readmore {
    .ce_counseling_list {
        h3 {
            margin-bottom: 1em;
        }

        + .ce_counseling_list {
            margin-top: $vmargin-mobile;

            @include medium {
                margin-top: $vmargin-desktop;
            }
        }
    }

    .mod_counseling_list {
        h3 {
            margin-bottom: 1em;
            padding: 0 $indent-mobile;

            @include medium {
                padding: 0 $indent-tablet;
            }

            @include large {
                max-width: 1920px;
                margin: 0 auto 1em;
                padding: 0 $indent-desktop;
            }
        }

        + .mod_counseling_list {
            margin-top: $vmargin-mobile;

            @include medium {
                margin-top: $vmargin-desktop;
            }
        }
    }
}

.mod_counseling_submit {
    clear: both;
    padding-top: $vmargin-mobile;

    @include medium {
        padding-top: $vmargin-desktop;
    }
}
