.widget {
    position: relative;
    display: block;
    margin: 0 0 18px;
    color: $text-color;
    font-size: inherit;
    font-family: $font-ClanPro;

    > label,
    .radio_container > legend,
    .checkbox_container > legend {
        position: absolute;
        top: -12px;
        left: 13px;
        margin: 0 1px 0 0;
        padding: 0 10px;
        overflow: hidden;
        font-size: 14px;
        font-family: $font-ClanProBold;
        white-space: nowrap;
        text-overflow: ellipsis;
        background: #fff;
        cursor: text;
        transition: all 0.2s;
    }

    select {
        appearance: none;
    }

    textarea {
        width: 100%;
        min-height: 250px;
    }

    .radio_container > span,
    .checkbox_container > span {
        display: inline-block;
        margin: 0.3em 0.6em 0.3em 0;
    }

    input.text,
    select,
    textarea,
    .radio_container,
    .checkbox_container {
        width: 100%;
        padding: 14px 23px 10px;
        font-size: inherit;
        background: none;
        border: 2px solid $border-color;
        border-radius: 6px;
        box-shadow: none;

        &.no-label {
            padding-top: 15px;
            padding-bottom: 13px;
        }

        &::placeholder {
            font-family: $font-ClanPro;
            opacity: 1;
            transition: all 0.2s;
        }

        &:placeholder-shown:not(:focus, .no-label)::placeholder {
            opacity: 0;
        }

        &:focus {
            border-color: #008faa;
            outline: none;
        }

        &:focus + label {
            color: #008faa;
        }

        &:placeholder-shown:not(:focus),
        &.no-placeholder:not(:focus),
        &.empty {
            border-color: $border-color;

            & + label {
                top: 13px;
                width: 80%;
                color: $border-color;
                font-size: inherit;
                pointer-events: none;
            }
        }
    }

    input,
    textarea,
    select {
        font-size: 16px !important;
    }

    input[type='number'] {
        appearance: textfield;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            margin: 0;
            appearance: none;
        }
    }

    input[type='radio'] {
        position: absolute;
        visibility: hidden;

        + label {
            position: relative;
            padding-left: 18px;

            &::before {
                position: absolute;
                top: -1px;
                left: 0;
                display: block;
                width: 14px;
                height: 14px;
                border: 2px solid $text-color;
                border-radius: 50%;
                transition: border-color 0.1s ease-in-out;
                content: '';
            }

            &::after {
                position: absolute;
                top: 3px;
                left: 4px;
                display: block;
                width: 6px;
                height: 6px;
                background: #008faa;
                border-radius: 50%;
                opacity: 0;
                transition: opacity 0.1s ease-in-out;
                content: '';
            }
        }

        &:checked {
            + label {
                &::before {
                    border-color: #008faa;
                }

                &::after {
                    opacity: 1;
                }
            }
        }
    }

    select {
        position: relative;
        padding-right: 1em;
        font-family: $font-ClanProBold;
        background: url('../images/select.svg') no-repeat right 16px center;
        background-size: 30px 9px;

        &:not(.empty) {
            border-color: $text-color;
        }

        &::after {
            position: absolute;
            top: 5px;
            right: 5px;
            border-top: 2px solid $text-color;
            border-right: 2px solid $text-color;
            content: '';
        }
    }

    &-submit {
        border: none;

        button {
            @extend .link-button;
        }
    }

    p.error {
        position: relative;
        margin: 10px 0 0;
        padding: 8px 23px 7px;
        color: #fff;
        font-size: 0.8em;
        background: $error-color;
        border-radius: 6px;

        &::before {
            position: absolute;
            top: -5px;
            left: 28px;
            width: 10px;
            height: 10px;
            background: $error-color;
            transform: rotateZ(45deg);
            content: '';
        }
    }
}

@include medium {
    .widget {
        margin-bottom: $vmargin-mobile;

        input,
        textarea,
        select {
            font-size: inherit !important;
        }

        input[type='radio'] {
            + label {
                padding-left: 28px;

                &::before {
                    top: 0;
                    left: 0;
                    width: 20px;
                    height: 20px;
                }

                &::after {
                    top: 5px;
                    left: 5px;
                    width: 10px;
                    height: 10px;
                }
            }
        }

        select {
            background-size: 44px 13px;
        }
    }
}
